<template>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <img v-bind:src="nft.image" />
      </div>
      <div class="col-9">
        <template v-if="nft.owner">
          <p>
            Owner: <MetamaskAddress v-bind:address="nft.owner" short="yes"></MetamaskAddress>
          </p>
          <!--MarketV3 ca="0x1De097da3Fe906137ece38a0583dA427b99e566e" v-bind:owner="nft.owner" v-bind:id="$route.params.id"></MarketV3-->
          <!--Bid ca="0x1De097da3Fe906137ece38a0583dA427b99e566e" v-bind:owner="nft.owner" v-bind:id="$route.params.id"></Bid-->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
//import Bid from "../../components/Rat/Bid";
//import MarketV3 from "../../components/Rat/MarketV3";
import MetamaskAddress from "../../components/MetamaskAddress";
import {nftCA} from "../../../config";

import nftInfo from '../../../artifacts/contracts/RatCollectibles.sol/NFT.json';
import {ethers} from "ethers";

export default {
  components: {
    //MarketV3,
    //Bid,
    MetamaskAddress},
  data() {
    return {
      nftCA: nftCA,
      nft: {
        image: null,
        owner: null,
      },
    }
  },
  watch: {
    '$store.state.provider'() {
      this.loadCollectible();
    }
  },
  methods: {
    loadCollectible: function() {
      if (this.$store.state.provider) {
        this.nft.image = 'https://raw.githubusercontent.com/shadowkite/rat-collectibles/main/images/' + this.$route.params.id + '.webp';
        let nftContract = new ethers.Contract('0x1De097da3Fe906137ece38a0583dA427b99e566e', nftInfo.abi, this.$store.state.provider);
        nftContract.ownerOf(this.$route.params.id).then(owner => {
          this.nft.owner = owner;
        });
      }
    }
  },
  mounted() {
    this.loadCollectible();
  }
}
</script>